#loading-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  color: #fff;
  z-index: 999;
}
#loading-overlay .inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Aeonik, sans-serif;
}
.wrapper {
  width: 100%;
  height: 100vh;
  /* margin-top: 125px; */
  position: relative;
  top: 0 !important;
  overflow: hidden;
}
canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  object-fit: cover;
}
.caption {
  position: absolute;
  top: 50vh;
  right: 10%;
  left: 10%;
  z-index: 2;
  transform: translateY(-50%);
  padding: 0;
  display: flex;
  align-items: center;
}
.caption .list {
  flex: 1;
  cursor: default;
  will-change: transform;
  font-size: 14px;
  font-family: Aeonik, sans-serif;
  transition: color .8s;
}
.caption .list ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.caption .list ul li {
  margin: 12px 0;
  position: relative;
  color: rgba(255,255,255,.5);
  transition: all .5s ease;
  font-family: Wotfard;
}
.caption .list ul li.active {
  color: #6aeeff;
}
.caption .list ul li.active:before {
  content: '';
  position: absolute;
  top: 3px;
  left: -15px;
  margin: auto;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid #fff;
  height: 0;
  width: 6px;
}
.caption .brief {
  width: 400px;
  color: #fff;
  position: relative;
  font-family: Wotfard;
}
.caption .brief .item {
  visibility: hidden;
  opacity: 0;
  font-family: Aeonik, sans-serif;
  transition: all .5s ease;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.caption .brief .item h1 {
  font-size: 55px;
  font-weight: 400;
  margin: 10px 0;
  font-family: Wotfard;
}
.caption .brief .item p {
  font: 400 13px/22px Poppins;
}
.caption .brief h3 {
  font-family: Wotfard;
}
.caption .brief h5 {
  font-size: 18px;
  margin-bottom: 15px;
  line-height: 28px;
  color: #6aeeff;
  font-family: Wotfard;
}
.caption .brief a,
.btn-outline-white {
  color: #fff !important;
  font-size: 14px;
  display: inline-block;
  margin-top: 10px;
  border: 1px solid #fff;
  padding: 10px 25px;
  text-decoration: none;
  border-radius: 50px;
  cursor: pointer;
  font-family: Wotfard;
  -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
      transition: all .5s ease;
  /* text-shadow: 0 1px 5px rgba(0,0,0,.8); */
}
.caption .brief a:hover,
.btn-outline-white:hover {
  background-color: #fff;
  color: #000 !important
}
.caption .brief .item.active {
  visibility: visible;
  opacity: 1;
}
.loading #percent {
  font-size: 28px;
  color: #fff;
}
.percent-loader {
  width: 202px;
  height: 4px;
  background: rgba(255,255,255,.7);
  border-radius: 50px;
  position: relative;
}
.percent-loader #loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 0%;
  background: -webkit-linear-gradient(169deg, #004FEE 0%, #EE4492 100%);
  border-radius: 50px;
  -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
      transition: all .3s ease;
}
@media (max-width: 767px) {
  .wrapper {
    background: #000;
  }
  .caption .brief .item {
    display: none;
    position: initial;
    transform: none;
    color: #fff;
    padding: 15px 20px 0;
  }
  .caption .brief .item.active {
    display: block;
  }
  canvas {
    object-fit: cover;
    height: 48vh;
    position: initial;
  }
  .caption {
    position: relative;
    transform: none;
    top: -50px;
    right: 0;
    left: 0;
    z-index: 999999999;
    background: #000;
    align-items: start;
    padding-bottom: 10px;
  }
  .caption:before {
    content: "";
    position: absolute;
    right: 0;
    left: 0;
    height: 50px;
    background: linear-gradient(to bottom, rgba(30, 87, 153, 0) 0%, rgba(0, 0, 0, 1) 100%);
    margin-top: 0;
    top: -40px;
    z-index: 1;
  }
  .caption .list {
    display: none;
  }
  .caption .brief {
    width: 100%;
  }
  .caption .brief,
  .caption .brief a {
    color: #000
  }
  .caption .brief .item p,
  .caption .brief a {
    text-shadow: none;
  }
  .caption .brief .item h1 {
    font-size: 40px;
  }
  .caption .brief h5 {
    font-size: 14px;
    margin-bottom: 6px;
    line-height: 20px;
  }
  .caption .brief .item p {
    font: 400 13px / 18px Poppins;
    margin-bottom: 5px;
  }
  .caption .brief a {
    font-size: 12px;
    margin-top: 6px;
    padding: 6px 20px;
  }
  .caption .brief h3 {
    font-size: 1.40rem;
    margin-bottom: 5px
  }
}
@media (min-width: 1200px) and (max-width: 1600px) {
  .caption {
    right: 4%;
    left: 4%
  }
  .caption .brief {
    width: 350px;
  }
}
@media (min-width: 768px) and (max-width: 1200px) {
  .caption .list {
    display: none;
  }
  .caption {
    top: initial;
    transform: none;
    bottom: 20%;
    left: 10%;
    right: 10%;
  }
  .caption .brief {
    width: 100%;
    text-align: center;
  }
}
@font-face {
  font-family: 'Wotfard';
  src: url('./assets/fonts/Wotfard-Thin.woff2') format('woff2'),
      url('./assets/fonts/Wotfard-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Wotfard';
  src: url('./assets/fonts/Wotfard-ThinItalic.woff2') format('woff2'),
      url('./assets/fonts/Wotfard-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Wotfard';
  src: url('./assets/fonts/Wotfard-SemiBoldItalic.woff2') format('woff2'),
      url('./assets/fonts/Wotfard-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Wotfard';
  src: url('./assets/fonts/Wotfard-SemiBold.woff2') format('woff2'),
      url('./assets/fonts/Wotfard-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Wotfard Regular';
  src: url('./assets/fonts/Wotfard-RegularItalic.woff2') format('woff2'),
      url('./assets/fonts/Wotfard-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Wotfard';
  src: url('./assets/fonts/Wotfard-MediumItalic.woff2') format('woff2'),
      url('./assets/fonts/Wotfard-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Wotfard';
  src: url('./assets/fonts/Wotfard-Regular.woff2') format('woff2'),
      url('./assets/fonts/Wotfard-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Wotfard';
  src: url('./assets/fonts/Wotfard-ExtraLight.woff2') format('woff2'),
      url('./assets/fonts/Wotfard-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Wotfard';
  src: url('./assets/fonts/Wotfard-LightItalic.woff2') format('woff2'),
      url('./assets/fonts/Wotfard-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Wotfard';
  src: url('./assets/fonts/Wotfard-ExtraLightItalic.woff2') format('woff2'),
      url('./assets/fonts/Wotfard-ExtraLightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Wotfard';
  src: url('./assets/fonts/Wotfard-Medium.woff2') format('woff2'),
      url('./assets/fonts/Wotfard-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Wotfard';
  src: url('./assets/fonts/Wotfard-Light.woff2') format('woff2'),
      url('./assets/fonts/Wotfard-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Wotfard';
  src: url('./assets/fonts/Wotfard-ExtraLight.woff2') format('woff2'),
      url('./assets/fonts/Wotfard-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Wotfard';
  src: url('./assets/fonts/Wotfard-Bold.woff2') format('woff2'),
      url('./assets/fonts/Wotfard-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Wotfard';
  src: url('./assets/fonts/Wotfard-BoldItalic.woff2') format('woff2'),
      url('./assets/fonts/Wotfard-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Wotfard';
  src: url('./assets/fonts/Wotfard-BoldItalic.woff2') format('woff2'),
      url('./assets/fonts/Wotfard-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Wotfard';
  src: url('./assets/fonts/Wotfard-SemiBoldItalic.woff2') format('woff2'),
      url('./assets/fonts/Wotfard-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Wotfard';
  src: url('./assets/fonts/Wotfard-SemiBold.woff2') format('woff2'),
      url('./assets/fonts/Wotfard-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Wotfard';
  src: url('./assets/fonts/Wotfard-Light.woff2') format('woff2'),
      url('./assets/fonts/Wotfard-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Wotfard';
  src: url('./assets/fonts/Wotfard-Bold.woff2') format('woff2'),
      url('./assets/fonts/Wotfard-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Wotfard';
  src: url('./assets/fonts/Wotfard-Regular.woff2') format('woff2'),
      url('./assets/fonts/Wotfard-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Wotfard';
  src: url('./assets/fonts/Wotfard-LightItalic.woff2') format('woff2'),
      url('./assets/fonts/Wotfard-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Wotfard';
  src: url('./assets/fonts/Wotfard-Medium.woff2') format('woff2'),
      url('./assets/fonts/Wotfard-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Wotfard';
  src: url('./assets/fonts/Wotfard-Thin.woff2') format('woff2'),
      url('./assets/fonts/Wotfard-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Wotfard';
  src: url('./assets/fonts/Wotfard-ExtraLightItalic.woff2') format('woff2'),
      url('./assets/fonts/Wotfard-ExtraLightItalic.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Wotfard';
  src: url('./assets/fonts/Wotfard-ThinItalic.woff2') format('woff2'),
      url('./assets/fonts/Wotfard-ThinItalic.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Wotfard';
  src: url('./assets/fonts/Wotfard-MediumItalic.woff2') format('woff2'),
      url('./assets/fonts/Wotfard-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Wotfard';
  src: url('./assets/fonts/Wotfard-RegularItalic.woff2') format('woff2'),
      url('./assets/fonts/Wotfard-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
:root {
	--bs-primary-rgb: 35, 17, 96;
}
div#root {
    overflow-x: hidden;
}
body {
  font-family: "Poppins", sans-serif;
  /* background: linear-gradient(180deg, #00031B 0%, #060826 47%, #23011E 100%); */
  background-image: url('./assets/images/background.jpg');
  background-repeat: repeat-y;
  background-size: cover;
  background-position: top center;
	color: #fff;
	overflow-x: hidden;
}
img {max-width: 100%}
.navbar{
  padding: 2.5rem 0;
  background: linear-gradient(rgb(0, 0, 0) 4px, rgba(0, 0, 0, 0.745) 32px, rgba(0, 0, 0, 0) 80px) no-repeat;  
}
.nav-link{
  font: normal normal 300 15px/18px Wotfard;
  letter-spacing: 0px;
  color: #FFFFFF;
}
.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show,
.nav-link:hover,
.nav-link:active,
.nav-link:focus{
	color: #EE4492;
}
.navbar-brand img{
  height: 35px;
}

.text-pri{
	color: #EE4492;
}
.text-sec{
	color: #0dbdd4;	
}
@media (min-width: 992px) {
	.navbar-toggler {
		display:none
	}
}
.h1, h1 {
	font-size: 4.875rem;
  line-height: 80%;
}
.h2, h2 {
	font-size: 3.125rem;
}
.title-h2,.h3, h3 {
	font-size: 2.188rem;
}
.h4, h4 {
	font-size: 1.58em;
}
.h5, h5 {
	font-size: 1.375em;
}
.h6, h6 {
	font-size: 1em;
}
.h7{
	font-size: 1.78em;
}
.fs-23{
	font-size: 1.45em;
}
.title-sm{
  font-size: 1rem;
  line-height: 25px;
  letter-spacing: 0px;
  color: rgba(255, 116, 180, 1);
}
p{
	font-size: 1.063rem;
	color: rgba(255, 255, 255);
}
.p-lg{
  	font-size: 1.125rem;
}
.p-md{
	font-size: 1.25rem;
}
.p-sm{
	font-size: 0.875rem;
}
p a{
	color: rgba(255, 255, 255, 0.6);	
	text-decoration: none;
}
p a:hover,
p a:focus,
p a:active{
	color: rgba(255, 255, 255, 0.8);
}
.fs-20{
	font-size: 1.25em;
}
section,
footer {
	position: relative;
	overflow: hidden
}
.container {
	position: relative;
	z-index: 1;
}


.bg-gray {
	background: #f1ebf9
}
.rounded-xl {
	border-radius: 25px;
}
.btn {
	display: inline-block;
	font-weight: 400;
	line-height: 1.5;
	color: #212529;
	text-align: center;
	text-decoration: none;
	vertical-align: middle;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
	background-color: transparent;
	border: 1px solid transparent;
	padding: 0.375em 0.75em;
	font-size: 1em;
	border-radius: 0.25em;
	transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.btn-primary {
	color: #e3d8f3;
	background-color: rgba(238, 68, 146, 1);
	border-color: rgba(238, 68, 146, 1);
}
.btn-outline-white{
	color: #ffffff;
	border: 1px solid rgba(255, 255, 255, 0.3);
	background-color: transparent;	
}
.btn-outline-white:hover{
	color:	#000;
	border: 1px solid #ffffff;	
	background-color: #ffffff;	
}
.btn-xl{
	font-size: 1.875rem;
	text-transform: capitalize;
	font-weight: 500;
  padding: 0.45em 1.7em;
}
.btn-md{
	font-size: 1rem;
	text-transform: capitalize;
	font-weight: 500;
  padding: 0.75em 3em;
}
.btn-primary:hover {
	background-color: transparent;
	border-color: rgba(238, 68, 146, 1);
	color: rgba(238, 68, 146, 1);
}
.bg-pink {background: #faf7fe}
.bg-light-pink {background: #f9f6fc}
.btn-lg {
	font-size: 1.5em;
	border-radius: 0.3em;
	font-weight: 500;
	height: 80px;
	min-width: 260px;
	display: inline-flex;
	align-items: center;
	justify-content: center
}

.three-dots{
	letter-spacing: 5px;
	color: rgba(255, 255, 255, 0.4);
    font-size: 2em;
}
.fw-600 {font-weight: 700}

.gradient-text-1{
	background: -webkit-linear-gradient(169deg, #004FEE 0%, #EE4492 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.modal-100w {
    max-width: none !important;
    width: 100%;
}
.norounded .modal-content{
	border: 0;
	border-radius: 0;
}
.dark-blue{
	color: #7361FF;
}
/* .contact-form {
	background: #fff;
	border-radius: 52px;
	box-shadow: 0px 14px 99px #7B5AA73D;
}
.form-control {
	min-height: 65px;
	background: #1E2125;
	border: 0;
	border-radius: 24px;
	width: 100%;
	padding: 0 30px;
	font-size: 14px;
	resize: none;
	outline: none;
	color: #fff;
}
.form-control::placeholder{
	font: normal normal 500 20px/65px;
	letter-spacing: 0px;
	color: rgba(255, 255, 255, 0.5);
} */

.copyright { font: normal normal 300 12px/14px Wotfard;
  letter-spacing: 0px;
  color: rgba(255, 255, 255, .5);
}
.footer-border,
.footer-links {
	border-top: 1px solid #323043
}
.inline-items {
	display: flex;
	align-items: center;
	justify-content: center;
}
.inline-items .link {text-decoration: none; font: normal normal 300 14px/18px Wotfard;
  letter-spacing: 0px;
  color: rgba(255, 255, 255, 1); margin: 0 30px}
.social-links {
	display: flex;
	align-items: center;
	justify-content: center
}
.social-links a {
	text-decoration: none;
	margin: 0 10px
}
.splide__list {
	height: auto !important
}
.splide__arow{
	width: 54px;
	height: 54px;
	border-radius: 50%;
	box-shadow: 0px 3px 34px #0000001C;
	background-color: #FFFFFF;
	border: 0;
}

.splide__arow:hover svg path{
	fill: #fff;    
}
.splide__arrows{
	gap: 40px;
	display: inline-flex;
}
.testimonial {
	background: #F9F6FC;
}

.text-default,.text-default:hover {color: #7d56a5}
.text-justify {
	text-align: justify;
}

@media (max-width: 767px) {
	.h1, h1 {
    font-size: 2em;
	}
	.h2, h2 {
    font-size: 2em;
	}
	.h3, h3 {
    font-size: 1.5em;
	}
	.h4, h4 {
    font-size: 1em;
	}
	.h5, h5 {
    font-size: 1em;
	}
	.btn-lg {
    font-size: 1.2em;
    height: 68px;
	}
	img {
    max-width: 100%;
	}
	iframe.mb-4 {
    max-height: 28vh;
	}

	.inline-items {
    flex-wrap: wrap;
	}
	.inline-items .link {
    font-size: 16px;
    margin: 4px 14px;
	}
	.navbar {
    padding: 10px 0
	}
	.navbar-collapse ul.navbar-nav {
    flex-wrap: wrap;
    justify-content: center;
	}
	.navbar-collapse ul.navbar-nav .nav-link {
    padding: 5px 10px;
    font-size: 14px;
	}
}


@media (min-width: 1200px){
	body{
		font-size: 13px;
	}
}
@media (min-width: 1600px){
	body{
		font-size: 16px;
	}
}


@media (min-width: 768px) and (max-width: 1024px) {
	.timeline-count{
		font-size: 2.5rem !important;
	}
	.banner {
		height: 75vh !important;
	}
	.h3, h3 {
    font-size: 1.8em;
	}
	.h4, h4 {
    font-size: 1.2em;
	}
	.h5, h5 {
    font-size: 1.2em;
	}
}

.py-10{
	padding-top: 6.25em;
	padding-bottom: 6.25em;
}
.mt-150{
	margin-top: 9.375em;
}
.mb-150{
	margin-bottom: 6.375em;
}
.border-4A4A4A{
	border: 1px solid #4A4A4A;
	border-radius: 6px;
	padding: 3px;
}
h3.sm-heading{
	font-size: 1.625em;
}
.mb-5rem{
	margin-bottom: 5em !important;
}
.fs-22{
	font-size: 1.375em;
}
.fs-14{
	font-size: 0.875em;	
}
.form-label{
	font: normal normal normal 11px/19px Poppins;
	letter-spacing: 0px;
	color:rgba(255, 255, 255, 0.56);
}

.fs-18{
	font-size: 1.125em;
}
.span-fs-14{
	font-size: 0.875em;
	line-height: 21px;
	letter-spacing: 0.28px;
	color: rgba(255, 255, 255, 0.8);
	text-transform: capitalize;
}
.social{
	border-radius: 50%;
	width: 36px;
	height: 36px;
	background: rgba(255, 255, 255, 0.1);
	display: inline-flex;
	justify-content: center;
	align-items: center;
	font-weight: bold;	
}
.logo img{
	height: 170px;
}

.footer_logo{
	height: 55px;
}


.banner{
	height: 100vh;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

.h2-lg,
.banner__content h2{
   font-size: 3.75rem; 
}
.banner__content p{
  /* font-size: 1.375rem;  */
  font-size: 1.65rem; 
  line-height: 130%;
}

.pill-pagination.splide__pagination{
  bottom: -1.5em;
  position: relative;
  gap:10px
}
.pill-pagination .splide__pagination__page{
  width: 11px;
  height: 11px;
  background: rgba(238, 68, 146, 1);
  opacity: 1;
}
.pill-pagination .splide__pagination__page.is-active{
  width: 45px;
  height: 11px;
  border-radius: 15px;
  background: rgba(238, 68, 146, 1);
}
.ms-n3{
  margin-left: -2rem;
}
.input-append-button{
  height: 60px;
}
.input-append-button input{
  border: 1px solid rgba(255, 255, 255, 0.5);
  font: normal normal normal 14px/20px Wotfard;
  letter-spacing: 0px;
  color: rgba(255, 255, 255, 1);
  background-color: transparent;
  padding: 22px 10em 22px 35px;
  outline: none;
}
.input-append-button input::placeholder{
  color: rgba(255, 255, 255, 0.5);
}
.input-append-button button{
	border-color: rgba(255, 255, 255, 0.5);
	font: normal normal 500 16px/19px Wotfard;
	border-left: 0;
  padding: 0.375em 2.2em;
}
.input-append-button button:hover{
	border-color: rgba(255, 255, 255, 0.5);
	background-color: rgba(238, 68, 146, 1);
	color: #fff;
}
.text-xm{
  font: normal normal normal 14px/32px Wotfard;
letter-spacing: 0px;
color: rgba(255, 255, 255, 1);
opacity: 0.45;
}
.roadmap-text{
  font-size: 1rem;
  font-weight: 600;
  line-height: 130%;
  letter-spacing: 0.36px;
  color: rgba(255, 255, 255, 0.5);
}
.roadmap-swiper .splide__slide.roadmap-swiper-slider-slide:nth-child(even){
  flex-direction: column-reverse !important;
}
.roadmap-swiper .splide__track{
  /* height: 440px !important; */
  position: relative;
  overflow: visible !important;
}
.roadmap-swiper .splide__track::before{
  content: '';
  position: absolute;
  top: 33%;
  width: 100%;
  height: 8px;
  background-color:rgba(238, 68, 146, .3);
}
.roadmap-swiper .splide__list{
  height: 100% !important;
}

.roadmap-swiper .splide__slide.roadmap-swiper-slider-slide{
  position: relative;
}
.timeline-count:before {
    background: #ee4492;
    border-radius: 50%;
    bottom: -8%;
    content: "";
    height: .3em;
    position: absolute;
    right: -7.5%;
    width: .3em;
}
.timeline-count{
	position: relative;
}
.timeline-count:after {
    background: #ee4492;
    border: 1px solid #ee4492;
    content: "";
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 0;
} 

/* .bg-one,
.bg-two,
.bg-two-way{
	position: relative;
}
.bg-one::before{
	content: '';
	position: absolute;
	width: 100%;
	height: 50%;
	opacity: 0.42;
	bottom: 20%;
	filter: blur(30px);
	transform: matrix(0.96, -0.29, 0.29, 0.96, 0, 0);
	background: transparent linear-gradient(180deg, rgba(23, 24, 68, 0) 0%, rgba(238, 68, 146, 1) 46%, rgba(23, 24, 68, 0) 100%) 0% 0% no-repeat padding-box;
}
.bg-two::before{
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	opacity: 0.42;
	bottom: 0%;
	filter: blur(30px);
	background: transparent linear-gradient(0deg, rgba(23, 24, 68, 0) 0%, rgba(0, 8, 232, 1) 46%, rgba(23, 24, 68, 0) 100%) 0% 0% no-repeat padding-box;
	transform: matrix(-0.96, -0.29, 0.29, -0.96, 0, 0);
}
.bg-two-way::after{
	content: '';
	position: absolute;
    width: 100%;
    height: 27%;
    opacity: 0.42;
    top: 16%;
	filter: blur(30px);
	transform: matrix(0.96, -0.29, 0.29, 0.96, 0, 0);
	background: transparent linear-gradient(180deg, rgba(23, 24, 68, 0) 0%, rgba(238, 68, 146, 1) 46%, rgba(23, 24, 68, 0) 100%) 0% 0% no-repeat padding-box;
	z-index: -1;
}
.bg-two-way::before{
	content: '';
	position: absolute;
	width: 100%;
	height: 50%;
	opacity: 0.42;
	bottom: 0%;
	filter: blur(30px);
	background: transparent linear-gradient(0deg, rgba(23, 24, 68, 0) 0%, rgba(0, 8, 232, 1) 46%, rgba(23, 24, 68, 0) 100%) 0% 0% no-repeat padding-box;
	transform: matrix(-0.96, -0.29, 0.29, -0.96, 0, 0);
	z-index: -1;
} */

  .orbit-wrap {
	list-style: none;
  }
  .orbit-wrap > li:hover ul {
	border-width: 2px;
	border-color: #fff;
  }
  .orbit-wrap > li:hover ~ li ul {
	border-color: rgba(255, 255, 255, 0.2);
  }
  .orbit-wrap > li:hover ~ li ul li {
	opacity: 0.4;
  }
  
  ul[class^=ring] {
	transition: all 300ms ease-in-out;
  }
  ul[class^=ring] li {
	transition: all 300ms ease-in-out;
  }
  
  .ring-0 {
	width: 25em;
	height: 25em;
	-webkit-animation: clockwiseRotate 20s linear infinite;
			animation: clockwiseRotate 20s linear infinite;
  }
  .ring-0 img {
	-webkit-animation: counterClockwiseRotate 20s linear infinite;
			animation: counterClockwiseRotate 20s linear infinite;
  }
  
  .ring-0 > *:nth-of-type(1) {
	transform: rotate(270deg) translate(12.5em) rotate(-270deg);
  }
  
  .ring-0 > *:nth-of-type(2) {
	transform: rotate(30deg) translate(12.5em) rotate(-30deg);
 }
  
  .ring-0 > *:nth-of-type(3) {
	transform: rotate(145deg) translate(12.5em) rotate(-145deg);
  }
  
  
  ul[class^=ring] {
	border: solid 2px #ee4492;
	position: relative;
	padding: 0;
	border-radius: 50%;
	list-style: none;
	box-sizing: content-box;
  }
  
  ul[class^=ring] li {
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -2.8em;
  }
  
  /*
	center;
  */
  .orbit-center {
	z-index: 5;
	font-size: 2em;
	width: 6.5em;
	height: 4.5em;
	line-height: 1.8em;
	text-align: center;
  }
  .orbit-center:hover .orbit-center__icon {
	transform: rotateZ(0deg);
  }
  
  .orbit-center__icon {
	transform: rotateZ(-360deg);
	transition: all 300ms ease-in-out;
  }
  
  
  /* 
  animations 
  */
  @-webkit-keyframes clockwiseRotate {
	from {
	  transform: rotate(0deg);
	}
	to {
	  transform: rotate(360deg);
	}
  }
  @keyframes clockwiseRotate {
	from {
	  transform: rotate(0deg);
	}
	to {
	  transform: rotate(360deg);
	}
  }
  @-webkit-keyframes counterClockwiseRotate {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(-360deg);
	}
  }
  @keyframes counterClockwiseRotate {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(-360deg);
	}
  }
  
  .zoom-in-zoom-out {
	animation: zoom-in-zoom-out 3.5s ease-out infinite;
  }
  
  @keyframes zoom-in-zoom-out {
	0% {
	  transform: scale(1, 1);
	}
	50% {
	  transform: scale(0.8, 0.8);
	}
	100% {
	  transform: scale(1, 1);
	}
  }
  .ready-to-go{
	background-image: url('./assets/images/shapes/multi-circle.svg');
	background-repeat: no-repeat;
	background-position: -16% 100%;
  }

  @media (max-width:1500px){
	body{
		font-size: 13px;
	}
	.logo img{
		height: 130px;
	}
	.footer_logo{
		height: 80px;
	}
	/* .h1, h1 {
		font-size: 5.563em;
	} */
	/* .h2-lg, .banner__content h2 {
		font-size: 3.75rem;
	}
	.h1, h1 {
		font-size: 5.875rem;
	} */
	/* .banner{
		height: 731px;
	} */
}
@media (max-width:1199px){
	body{
		font-size: 10px;
	}
	.logo img{
		height: 100px;
	}
	.footer_logo{
		height: 80px;
	}
	.py-10 {
		padding-top: 5.25em;
		padding-bottom: 5.25em;
	}
	.btn-lg {
		font-size: 1.5em;
		height: 65px;
	}
}


 @media (max-width: 992px) {
	.h1, h1 {
		font-size: 5.563em;
	}
	.navbar-toggler{
		border-color: #ee4492;
	}
	.navbar-toggler{
		color: #ee4492;		
	}
	.navbar-toggler-icon{
		background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="800px" height="800px" viewBox="0 0 24 24" fill="none"><g clip-path="url(%23clip0_429_11066)"><path d="M3 6.00092H21M3 12.0009H21M3 18.0009H21" stroke="%23EE4492" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/></g><defs><clipPath id="clip0_429_11066"><rect width="24" height="24" fill="white" transform="translate(0 0.000915527)"/></clipPath></defs></svg>');
	}
	.offcanvas{
		max-width: 300px;
		background: linear-gradient(180deg, #00031B 0%, #060826 47%, #23011E 100%);
	}
	.nav-link{
		text-align: right;
	}
	.btn-close{
		filter: invert();
		opacity: 1;
	}
	.banner__content{
		text-align: center;
	}
	.h2, h2 {
		font-size: 2rem;
	}
	.title-h2, .h3, h3 {
		font-size: 2rem;
	}
	.banner__content h2{
		font-size: 2rem;		
	}
	.banner__content p {
		font-size: 1.2rem;
	}
	.h2-lg {
		font-size: 2.75rem;
	}
	.footer_logo {
		height: 60px;
	}
	.btn-xl {
		font-size: 1.3rem;
	}
	.Team-wrapper h2 {
		font: normal normal 500 40px / 45px Poppins !important;
	}
}

	
@media (max-width: 767px){
	.full-banner{
		background-size: cover;
		padding-top: 100px;
	}
	.h1, h1{
		text-align: center;
	}
	/* .roadmap-swiper .splide__track {
		height: 500px !important;
	} */
	span.timeline-heading.h-50 {
		height: 20% !important;
	}
	.timeline-list li::before{
		top: 1px !important;
	}
	.timeline-list {
		max-width: 208px;
		margin-left: -15px;
		font-size: 10px !important;
	}
	.roadmap-swiper .splide__pagination {
		bottom: -2em !important;
		position: relative !important; 
	}
	.py-10 {
		padding-top: 3.25em;
		padding-bottom: 3.25em;
	}
	.roadmap-pb-sm{
		padding-bottom: 5.25em;		
	}
	.Team-wrapper h2 {
		font: normal normal 500 35px / 40px Poppins !important;
	}
	.token-features h3 {
		font: normal normal 300 25px / 35px Poppins !important;
	}
}
@media (max-width: 575px){
	.h1, h1 {
		font-size: 3.563em;
	}
	.logo img {
		height: 80px;
	}
	.mt-150 {
		margin-top: 6.375em;
	}
	.inline-items .link {
		font-size: 14px;
		text-align: center;
	}
	.inline-items .navdropdown-style .nav-link{
		text-align: center !important;	 	
	}
	.banner {
		padding-top: 50px;
		padding-bottom: 50px;
	}
	.title-h2, .h3, h3 {
		font-size: 1.6rem;
	}
	.h2, h2 {
		font-size: 1.7rem;
	}
	.powerful-tool-img {
		height: 250px;
	}
	.orbit-center {
		width: 4.5em;
		height: 4.5em;
	}
	ul[class^=ring] li {
		margin: -4.8em;
	}
	.stuning-img {
		height: 200px;
	}
	.ready-to-go-img {
		height: 300px;
	}
	.custom-modal-style-1 .modal-content .modal-body {
		padding: 70px 15px !important;
	}
	.ignite {
		font-size: 1.7em;
	}
	.roadmap-swiper .splide__pagination {
		bottom: -5em;
	}
	.timeline-count {
		font: normal normal 600 2.2rem / 1 Poppins !important;
	}
	.timeline-list li::before{
		width: 11px !important;
		height: 11px !important;
	}
	.timeline-list {
		font-size: 10px !important;
	}
	.roadmap-swiper .splide__slide.roadmap-swiper-slider-slide{
		width: 65% !important;
	}
	.timeline-list li {
		margin-bottom: 2px !important;
	}
	.ignite{
		font-size: 1.7em;
	}
}
@media (max-width: 480px){
	.mt-150 {
		margin-top: 5.375em;
	}
	.powerful-tool-img {
		height: 200px;
	}
	.orbit-center {
		width: 4.5em;
		height: 4.5em;
	}
	ul[class^=ring] li {
		margin: -4.8em;
	}
	.stuning-img {
		height: 180px;
	}
	.ready-to-go-img {
		height: 250px;
	}
	.mb-150 {
		margin-bottom: 0;
	}
}
.h-280{
	height: 280px;
}
.newsUser{
	width: 100px;
	height: 100px;
	border: 5px solid #51204B;
	border-radius: 50%;
	display: inline-block;
	position: absolute;
	left: 0;
	right: 0;
	margin: auto;
	top: -15%;
}
h5.newsUserName {
    font: normal normal 500 17px/20px Wotfard;
    letter-spacing: 0.51px;
}

span.newsDatetime {
font: normal normal normal 12px/20px Wotfard;
letter-spacing: 0.36px;
color: rgba(255, 255, 255, .5);
text-transform: capitalize;
}
.newsTitle {
    font: normal normal 600 16px/22px Wotfard;
	letter-spacing: 0.48px;
	color: rgba(255, 255, 255, 1);
	text-transform: capitalize;

}
.newsDescription {
    font: normal normal 300 12px/18px Wotfard;
	letter-spacing: 0.36px;
	color: rgba(255, 255, 255, .5);
}
.newsLink {
    font: normal normal 500 13px/16px Wotfard;
	letter-spacing: 0px;
	color: rgba(238, 68, 146, 1);
	text-transform: capitalize;
    text-decoration:none;
}
.box-1{
	border-radius: 20px;
	backdrop-filter: blur(7px);
	-webkit-backdrop-filter: blur(7px);
	background-color: rgba(238, 68, 146, 0.2);
}
.box-1-content{
	padding: 60px 35px 15px;
}
.line-clamp-2 {
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;  
	overflow: hidden;
}
.bg-multi-circle{
	background-image: url('./assets/images/shapes/multi-circle.svg');
	background-repeat: no-repeat;
	background-position: -9% 100%;
}

.custom-pagtination.pagination{
	background: rgba(28, 21, 49, 1);
	border-radius: 30px;
	backdrop-filter: blur(30px);
	-webkit-backdrop-filter: blur(30px);
	padding: 11px 10px;
	display: inline-flex;
}
.custom-pagtination.pagination .page-item .page-link{
	background-color: transparent;
}
.page-link{
	font: normal normal 500 16px/19px Wotfard;
	letter-spacing: 0px;
	color: rgba(255, 255, 255, .23);
	text-transform: capitalize;
	border: 0;
}
.box-2{
	border-radius: 55px;
	backdrop-filter: blur(7px);
	-webkit-backdrop-filter: blur(7px);
	background-color: rgba(255, 255, 255, .05);
}
.token-features h3{
	font: normal normal 300 30px/40px Poppins;
	letter-spacing: 0.9px;
	color: rgba(255, 255, 255, 1);
}
.token-features p{
	font: normal normal 300 15px/25px Poppins;
	letter-spacing: 0px;
	color: rgba(255, 255, 255, .5);
}
.border-color-light{
	border-color: rgba(227, 216, 243, 0.185) !important;
}
.token-info{
	padding: 20px 15px;
	border-radius: 15px;
}
.Team-wrapper__img{
	width: 223px;
	height: 223px;
	border-radius: 50%;
	display: inline-block;
}
.Team-wrapper__img img{
	width: 100%;
	height: 100%;
	border-radius: 50%;
	object-fit: cover;
	object-position: top center;
}
.Team-wrapper h2{
	font: normal normal 500 45px/50px Poppins;
	letter-spacing: 1.35px;
	color: #FFFFFF;
}
.Team-wrapper__founder{
	font: normal normal 500 18px/18px Poppins;
	letter-spacing: 0.54px;
	color: #EE4492;
}
.Team-wrapper p{
	font: normal normal normal 15px/20px Poppins;
	letter-spacing: 0px;
	color: rgba(255, 255, 255, .7);
}

.custom-arrow .splide__arrow{
	position: relative;
	width: 55px !important;
	height: 55px !important;
	opacity: 1;
	transform: none;
}
.custom-arrow .splide__arrows {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
}
.custom-arrow .splide__arrow--prev{
	left: unset;
}
.custom-arrow .splide__arrow svg{
	width: 100%;
	height: 100%;
}
.custom-arrow .splide__arrow svg ellipse{
	/* fill: rgba(255, 255, 255, 0.2); */
	fill: #ee4492;	
}
.custom-arrow .splide__arrow:hover{
	outline: none;
}
.custom-arrow .splide__arrow:hover svg ellipse{
	fill: #ee4492;	
}
.advisor-wrapper__img{
	width: 179px;
	height: 179px;
	border-radius: 50%;
}
.advisor-wrapper__img img{
	width: 100%;
	height: 100%;
	border-radius: 50%;
	object-fit: cover;
	object-position: top center;
}
.advisor-wrapper h6{
	font: normal normal bold 20px/26px Poppins;
	letter-spacing: 0.4px;
	color: rgba(255, 255, 255, 1);
	text-transform: capitalize;
}
.advisor-wrapper span{
	font: normal normal bold 13px/20px Poppins;
	letter-spacing: 0.26px;
	color: rgba(255, 116, 180, 1);
	text-transform: capitalize;
}
.advisor-wrapper p{
	font: normal normal 300 12px/17px Poppins;
	letter-spacing: 0px;
	color: rgba(255, 255, 255, .5);
}
.fs-12{
	font: normal normal normal 12px/17px Poppins;
	letter-spacing: 0.17px;
	color: rgba(255, 255, 255, .7);
}
.fs-13{
	font: normal normal normal 13px/18px Poppins;
	letter-spacing: 0.17px;
	color: rgba(255, 255, 255, .8);
}
.fs-17{
	font: normal normal 600 17px/23px Poppins;
}
.form-style-1{
	background: rgba(0, 0, 0, .32) 0% 0% no-repeat padding-box;
	border-radius: 25px;
	padding: 50px 45px;
}
.form-type{
	border: 1px solid rgba(255, 255, 255, 0.08);
	border-radius: 3px;
	background-color: transparent;
	font-size: 13px;
	color: #fff;	
}
.form-type:hover,
.form-type:active,
.form-type:focus{
	border: 1px solid rgba(255, 255, 255, 0.08);
	background-color: transparent;
	color: #fff;	
	outline: none;
	box-shadow: none;
}
.joinWishlistInput{
	height: 60px;
	border: 1px solid rgba(255, 255, 255, .39);
	border-radius: 12px;
	font: normal normal normal 14px/20px Wotfard;
	background-color: transparent;
	color: #fff !important;
	outline: none;
}
.joinWishlistInput::placeholder,
.form-type::placeholder{
	color: rgba(255, 255, 255, 0.5);
}
.joinWishlistInput:focus{
	background-color: transparent;
	color: #fff !important;	
}
@media (max-width:1099px){
	.h-280 {
		height: 220px;
	}
}
@media (max-width:992px){
	.box-2 {
		border-radius: 40px;
	}
	.token-info {
		padding: 44px 40px;
	}
	/* .token-features-border{
		border: 0 !important;
	} */
	.navdropdown-style .dropdown-menu {
		text-align: end;
		display: inline-block;
		float: right;
	}
}
@media (max-width:767px){
	.token-features-border{
		border: 0 !important;
	}
}
.custom-modal-style-1 .modal-content{
	border-radius: 55px;
	overflow: hidden;
}
.custom-modal-style-1 .modal-content .modal-body {
	padding: 70px 50px;
}
.timeline-count{
	font: normal normal 600 3rem/1 Poppins;
	letter-spacing: 0px;
	color: #FFFFFF;
	text-transform: capitalize;
}
.timeline-heading{
	font: normal normal 500 0.9rem/150% Poppins;
	letter-spacing: 0px;
	color: #FFFFFF;
}
@media (min-width:1600px){
	.timeline-count{
		font: normal normal 600 5.313rem/85px Poppins;
	}
	.timeline-heading {
		font: normal normal 500 1.438rem/30px Poppins;
	}
	.h1, h1 {
		font-size: 6.875rem;
		line-height: 80%;
	}
	.custom-modal-style-1 .modal-content .modal-body{
		padding: 70px 150px;
	}
}

@media (max-width:1600px) and (min-width: 1200px){
	.timeline-count{
		font: normal normal 600 3.1rem/3.1rem Poppins;
	}
	.timeline-heading {
		font: normal normal 500 1rem/150% Poppins;
	}
	.timeline-count:before {
        right: -5.5%;
    }
}


@media (min-width: 768px) and (max-width: 1024px) {

}
.timeline-list{
	font: normal normal 600 12px/16px;
	letter-spacing: 0.36px;
	color: rgba(255, 255, 255, 0.85);
	/* min-height: 400px;
    max-height: 650px; */
}
.timeline-list li{
	position: relative;
	padding-left: 20px;
	margin-bottom: 5px;
}
.timeline-list li::before{
	content: '';
	background-image: url('./assets/images/icons/uncheck-i.svg');
    background-size: contain;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    left: 0;
    top: 5px;
    margin: auto;
    position: absolute;
    background-repeat: no-repeat;
}
.timeline-list li.completed::before{
	background-image: url('./assets/images/icons/check-i.svg');	
}

.custom-pagination{
    gap:20px;
	bottom: -5em;
}
.custom-pagination .splide__pagination__page {
    background: rgba(141, 68, 238, 0.41);
    width: 15px;
    height: 15px;
}

.custom-pagination .splide__pagination__page.is-active {
        background: rgba(141, 68, 238);
        transform: scale(1);
}
.trade-custom-modal .modal-dialog{
    max-width: 200px !important;
    width: 100%;
}
.tooltip.show{
	opacity: 1;
}
.trade-custom-modal .modal-content{
	background: rgba(238, 68, 146, 1) 0% 0% no-repeat padding-box;
	border-radius: 25px;
	padding: 34px;
}
.tradedesign{
	background: -webkit-linear-gradient(290deg, #004FEE 0%, #EE4492 100%);
	border-radius: 25px;
	padding: 34px;	
}
.custom-trade-tooltip{
	width: 180px;
}
.custom-trade-tooltip .tooltip-inner{
	background-color: transparent;
	padding: 0;
}
.custom-trade-tooltip .tooltip-arrow::after,
.custom-trade-tooltip .tooltip-arrow::before{
	content: none;
}
.tradeLink{
	font: normal normal 500 20px/30px Poppins;
	letter-spacing: 0px;
	color: rgba(255, 255, 255, 1);
	text-transform: capitalize;
	text-decoration: none;
}
.navdropdown-style .dropdown-toggle::after,
.navdropdown-style .dropdown-toggle::before{
	content: none;
}
.navdropdown-style .dropdown-menu{
background: linear-gradient(180deg, #00031B 0%, #060826 47%, #23011E 100%);
}
.navdropdown-style .dropdown-menu .dropdown-item{
	color: #fff;
	font-size: 14px;
}
.navdropdown-style .dropdown-menu .dropdown-item:hover,
.navdropdown-style .dropdown-menu .dropdown-item:active,
.navdropdown-style .dropdown-menu .dropdown-item:focus{
	background-color: transparent;
	font-weight: 500;
}
html::-webkit-scrollbar {
	display: none;
  }
  
  html::-webkit-scrollbar-track {
	background:transparent;
	-webkit-border-radius: 10px;
	border-radius: 10px;
  }
  
  html::-webkit-scrollbar-thumb {
	-webkit-border-radius: 10px;
	border-radius: 10px;
	background: rgba(255, 255, 255, 0.8);
  }
  
  html::-webkit-scrollbar-thumb:window-inactive {
	background: rgba(255, 255, 255, 0);
  }
  .video-react .video-react-control:before, .video-react .video-react-big-play-button:before {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.video-react .video-react-big-play-button {
	font-size: 22px;
	line-height: 100%;
	height: 60px;
	width: 60px;
	display: block;
	background-color: #fff;
	color: #000;
	border-radius: 50%;
  }
  .token-info-2,
  .token-info-3{
	font-size: 11px;
  }
  .token-info-1{
	font-size: 2.5em;
  }
  .opacity-75 {
    opacity: .85 !important;
}
.roadmap-row-first {
    height: 33%;
}
.roadmap-swiper .splide__pagination {
    bottom: -5em !important;
    position: relative !important;
}
.slick-track {
	display: flex;
	align-items: center;
}
@media (max-width: 767px) {
	.slick-track img {
		max-height: 50px
	}
}
a:focus-visible {
    outline: -webkit-focus-ring-color auto 0px !important;
}

@media (max-width:992px){
    .timeline-count:before {
        right: -6%;
    }
}
@media (max-width:767px){
    .timeline-count:before {
        right: -4.5%;
    }
}
@media (max-width:575px){
    .timeline-count:before {
        right: -5.5%;
    }
}
@media (max-width:480px){
    .timeline-count:before {
        right: -4%;
    }
}